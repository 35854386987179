import $ from 'jquery';
import {trackPersonalisation} from 'general/js/personalisationTracking';

$('.custom-select-our-craft').each(function () {
    var classes = $(this).attr('class');
    var dataAction = $(this).attr('data-action');
    var backroundColor = $(this).attr('data-backround-color');
    var template = '<div class="' + classes + '" data-action="' + dataAction + '">';
    template += '<span style="background-color: ' + backroundColor +
        ' !important;" class="custom-select-trigger-our-craft"><span class="select-header-our-craft">' +
        $(this).attr('placeholder') + '</span></span>';
    template += '<div class="custom-options-our-craft">';
    $(this).find('option').each(function () {
        const selectedClass = $(this).attr('selected') ? 'selection-our-craft' : '';
        template += '<span style="background-color: ' + backroundColor +
            ' !important;" class="custom-option-our-craft ' + $(this).attr('class') + ' ' +
            selectedClass + '" data-value="' + $(this).attr('value') +
            '" >' + $(this).html() + '</span>';
    });
    template += '</div></div>';

    $(this).wrap('<div class="custom-select-wrapper-our-craft"></div>');
    $(this).hide();
    $(this).after(template);
    addSelectedElement($(this).next());
});

$('.custom-option-our-craft:first-of-type').hover(function () {
    $(this).parents('.custom-options-our-craft').addClass('option-hover-our-craft');
}, function () {
    $(this).parents('.custom-options-our-craft').removeClass('option-hover-our-craft');
});

$('.custom-select-trigger-our-craft').on('click', function () {
    if (!$(this).parents('.custom-select-our-craft').hasClass('opened-our-craft')) {
        $('.custom-select-our-craft.opened-our-craft').removeClass('opened-our-craft');
        $('.custom-select-our-craft').find('.custom-select-trigger-our-craft').css('border-bottom', '0px');
        $(this).parents('.custom-select-our-craft').find('.custom-options-our-craft').removeClass('active-our-craft');
    }

    $(this).parents('.custom-select-our-craft').toggleClass('opened-our-craft');
    $(this).parents('.custom-select-our-craft').find('.custom-options-our-craft').toggleClass('active-our-craft');
    if (!$(this).parents('.custom-select-our-craft').hasClass('opened-our-craft')) {
        $(this).css('border-bottom', '0px');
    }
    event.stopPropagation();
});

$('.custom-option-our-craft').on('click', function (e) {
    let craftClassValue = $('.js-data-filter-select-class .select-header-our-craft').text().trim();
    let personalisationInfo = (((craftClassValue != 'All') && !$(e.target).parents('.js-data-filter-select-class').length)  ? craftClassValue + ' ' : '') +
        $(this).parents('.our-craft__category-section__filter-section__dropdown').find('.our-craft__category-section__filter-section__title').text().trim() +
        ': ' + $(this).text().trim();
    trackPersonalisation(personalisationInfo);
    $(this).parents('.custom-select-wrapper-our-craft').find('select').val($(this).data('value'));
    $(this).parents('.custom-options-our-craft').find('.custom-option-our-craft').removeClass('selection-our-craft');
    $(this).addClass('selection-our-craft');
    $(this).parents('.custom-select-our-craft').removeClass('opened-our-craft');
    $(this).parents('.custom-select-our-craft').find('.custom-select-trigger-our-craft > span').text($(this).text());
    filterOurCraftYachts();
    disableZeroResultOptions($(this).parents('.data-filter-select'));
});

$('.js-our-craft-reset').on('click', function() {
    resetFilters();
});

$('html').click(function (e) {
    if (!$(e.target).hasClass('custom-select-our-craft')) {
        $('.custom-select-our-craft').find('.custom-select-trigger-our-craft').css('border-bottom', '0px');

        $('.custom-select-our-craft').each(function () {
            $(this).removeClass('opened-our-craft');
        });
    }

    if (!$(e.target).hasClass('custom-select')) {
        $('.custom-select').find('.custom-select-trigger').css('border-bottom', '0px');

        $('.custom-select').each(function () {
            $(this).removeClass('opened');
        });
    }
});

function addSelectedElement(element) {
    const selectedOption = $(element[0]).find('.custom-options-our-craft').find('.selection-our-craft');
    $(element[0]).find('.custom-select-trigger-our-craft').html(`<span class="select-header-our-craft">${selectedOption.text()}</span>`);
    $(element[0]).find('.custom-select-wrapper-our-craft').find('select').val(selectedOption.data('value'));
}

