import $ from 'jquery';
import 'slick-carousel';
import { addInfiniteOnEdge } from 'general/js/carouselService';
import { sliderForTouchScreens } from 'general/js/carouselService';

$('.our-craft-section__carousel')
    .not('.slick-initialized')
    .slick({
        infinite: true,
        dots: false,
        arrows: true,
        prevArrow: $('.our-craft-section__arrows-section__left'),
        nextArrow: $('.our-craft-section__arrows-section__right'),
        slidesToShow: 4.4,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold:100,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3.8,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.7,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '30px',
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '35px',
                },
            },
            {
                breakpoint: 385,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '20px',
                },
            },
        ],
    });
    sliderForTouchScreens($('.our-craft-section__carousel'));
addInfiniteOnEdge('.our-craft-section__carousel');

const handleYachtCardClick = function (event) {
    const item = event.target;
    let url = '';

    if ($(item).parent().hasClass('model-item') || $(item).hasClass('model-item')) {
        url = $(item).parent().hasClass('model-item')
            ? $(item).parent().data('yatch-details-url')
            : $(item).data('yatch-details-url');
    } else {
        url = $(item).parents('.our-craft-section__carousel__item').data('category-url');
    }

    window.location.href = url;
};

$('.our-craft-section__carousel__item').on('click', handleYachtCardClick);
