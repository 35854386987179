import $ from 'jquery';

const hiddenModalClassName = 'our-craft-filter-modal__hidden';
const modalClassName = '.our-craft-filter-modal';

const openFilterModal = function () {
    const modal = $(modalClassName);
    if (modal.hasClass(hiddenModalClassName)) {
        modal.removeClass(hiddenModalClassName).fadeOut(0).fadeIn(500);
        $('body').css('overflow-y', 'hidden');
        $('body').css('position', 'fixed');
        $('#main-header-logo').addClass('black-logo');
        $('#menu-toggle').addClass('menu-toggle__close-button');
        $('#main-header').removeClass('sticky');
        $('#main-header').css('position', 'fixed');
    } else {
        if (navigator.userAgent.indexOf('Firefox') > 0) {
            $('body').css('overflow-y', 'auto');
        } else {
            $('body').css('overflow-y', 'overlay');
        }
        $('body').css('position', 'relative');
        modal.addClass(hiddenModalClassName).fadeIn(0).fadeOut(500);
        if (
            !(
                window.location.href.endsWith('our-craft') ||
                window.location.href.endsWith('our-craft/')
            )
        ) {
            $('#main-header-logo').removeClass('black-logo');
        }

        $('#main-header').css('position', '');
        $('#menu-toggle').removeClass('menu-toggle__close-button');
        if (document.documentElement.scrollTop > 74) {
            $('#main-header').addClass('sticky');
            $('#main-header-logo').addClass('black-logo');
        }
    }
};

$('.js-open-filter-modal').on(
    'click',
    function(e) {
        e.preventDefault();
        openFilterModal();
    }
);

$('.our-craft-filter-modal__content__dropdowns__dropdown').on(
    'click',
    function () {
        $(this).siblings().find('*').filter('.accordion-body').slideUp();
        $(this)
            .siblings()
            .find('*')
            .filter(
                '.our-craft-filter-modal__content__dropdowns__dropdown__header__arrow'
            )
            .css({
                transform: `rotate(45deg)`
            });
        const title = $(this)
            .find('*')
            .filter('.our-craft-filter-modal__content__dropdowns__dropdown__header');
        const arrow = $(this)
            .find('*')
            .filter(
                '.our-craft-filter-modal__content__dropdowns__dropdown__header__arrow'
            );
        const rotate = title.hasClass('accordion-active') ? -135 : 45;
        arrow.css({
            transform: `rotate(${rotate}deg)`
        });
    }
);

$('.js-switch').on(
    'click',
    function(e) {
        e.preventDefault();
        e.stopPropagation();
        if($(this).hasClass('is-active')) { return false; }
        else {
            $('.js-switch').toggleClass('is-active');
            if($(this).hasClass('js-metric')) {
                $('.is-metric').css('display', 'inline');
                $('.is-imperial').css('display', 'none');
            }
            if($(this).hasClass('js-imperial')) {
                $('.is-metric').css('display', 'none');
                $('.is-imperial').css('display', 'inline');
            }
        }
    }
);

$(document).on('ready', () => {
    $(
        '.our-craft-filter-modal__content__dropdowns__dropdown__options__option'
    ).attr('onclick', 'return true');
});

$('.our-craft-filter-modal__content__dropdowns__dropdown__options__option').on(
    'click',
    function () {
        const activeClassName =
            'our-craft-filter-modal__content__dropdowns__dropdown__options__option__active';
        $(this)
            .parent()
            .children()
            .map((i, el) => {
                if (this != el) {
                    $(el).removeClass(activeClassName);
                }
            });

        $(this).toggleClass(activeClassName);
        let filtersValues = getFiltersValues();
        filterMethod(filtersValues[0], filtersValues[1], filtersValues[2], false);
        disableZeroResultsOptionsMobile($(this).parent());
    }
);

$(document).on('readystatechange ', function () {
    if (
        window.location.href.endsWith('our-craft') ||
        window.location.href.endsWith('our-craft/')
    ) {
        $('#main-header-logo').addClass('black-logo');
        $('#menu-toggle').addClass('menu-toggle__open__blue');
    } else {
        $('#main-header-logo').removeClass('black-logo');
        $('#menu-toggle').removeClass('menu-toggle__open__blue');
    }
});

window.filterOurCraftYachts = function () {
    const classesFilter = $($('.js-data-filter-select-class')[0]).val();
    if(encodeURI(classesFilter) != window.location.href) {
        window.location.href = classesFilter;
    }
    const berthsFilterValue = $($('.js-data-filter-select-berths')[0]).val();
    const lengthFilterValue = $($('.js-data-filter-select-length')[0]).val();
    const crewFilterValue = $($('.js-data-filter-select-crew')[0]).val();
    filterMethod(berthsFilterValue, lengthFilterValue, crewFilterValue, true);
};

window.disableZeroResultOptions = function($parentItem) {

    if(!$($parentItem[0]).hasClass('js-data-filter-select-length')) {
        $($('.js-data-filter-select-length')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
        $($('.js-data-filter-select-length')[1]).find('.custom-option-our-craft').each(function (index) {
            var value = $(this).data('value');
            if (!value || value.toString().indexOf('-') <= -1) return;
            var lengthMin = value.toString().split('-')[0];
            var lengthMax = value.toString().split('-')[1];
            var counter = 0;
            $('.boat-data').each(function (index) {
                var length = $(this).data('boatlength');
                if (length <= lengthMax && length >= lengthMin && $(this).parent(':visible').length) counter++;
            });
            if (!counter) $(this).addClass('is-disabled');
        });
    }

    if(!$($parentItem[0]).hasClass('js-data-filter-select-berths')) {
        $($('.js-data-filter-select-berths')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
        $($('.js-data-filter-select-berths')[1]).find('.custom-option-our-craft').each(function (index) {
            var value = $(this).data('value');
            if (value && !$('.boat-data[data-maxberths="' + value + '"]').parent(':visible').length) $(this).addClass('is-disabled');
        });
    }

    if(!$($parentItem[0]).hasClass('js-data-filter-select-crew')) {
        $($('.js-data-filter-select-crew')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
        $($('.js-data-filter-select-crew')[1]).find('.custom-option-our-craft').each(function (index) {
            var value = $(this).data('value');
            if (value && !$('.boat-data[data-numberofcrew="' + value + '"]').parent(':visible').length) $(this).addClass('is-disabled');
        });
    }
};

const disableZeroResultsOptionsMobile = function($parentItem) {
    const activeClassName =
        'our-craft-filter-modal__content__dropdowns__dropdown__options__option__active';

    if($(`.${activeClassName}`).length == 0) {
        $('.our-craft-filter-modal__content__dropdowns__dropdown__options__option').removeClass('is-disabled');
        return;
    } else {
        $('.our-craft-filter-modal__content__dropdowns__dropdown__options')
            .not($parentItem)
            .find('.our-craft-filter-modal__content__dropdowns__dropdown__options__option')
            .removeClass('is-disabled');
    }

    if($parentItem.data('type') != 'by-berths') {  // not berths filter option is clicked
        if(!($(`.${activeClassName}`).length == 1 && $(`.${activeClassName}`).parent().data('type') == 'by-berths')) {  //not disable, if single selected option in the current category
            $('.our-craft-filter-modal__content__dropdowns__dropdown__options')
                .filter(function () {
                    return $(this).data('type') == 'by-berths';
                })
                .find('.our-craft-filter-modal__content__dropdowns__dropdown__options__option')
                .not(`.${activeClassName}`)
                .each(function () {
                    let value = $(this).text().trim();
                    if (value && !$('.boat-data[data-maxberths="' + value + '"]').parent().not('.would-be-hidden').length) $(this).addClass('is-disabled');
                });
        }
    }

    if($parentItem.data('type') != 'by-crew') {  // not crew filter option is clicked
        if(!($(`.${activeClassName}`).length == 1 && $(`.${activeClassName}`).parent().data('type') == 'by-crew')) {  //not disable, if single selected option in the current category
            $('.our-craft-filter-modal__content__dropdowns__dropdown__options')
                .filter(function () {
                    return $(this).data('type') == 'by-crew';
                })
                .find('.our-craft-filter-modal__content__dropdowns__dropdown__options__option')
                .not(`.${activeClassName}`)
                .each(function () {
                    let value = $(this).text().trim();
                    if (value && !$('.boat-data[data-numberofcrew="' + value + '"]').parent().not('.would-be-hidden').length) $(this).addClass('is-disabled');
                });
        }
    }

    if($parentItem.data('type') != 'by-length') {  // not length filter option is clicked
        if(!($(`.${activeClassName}`).length == 1 && $(`.${activeClassName}`).parent().data('type') == 'by-length')) {  //not disable, if single selected option in the current category
            $('.our-craft-filter-modal__content__dropdowns__dropdown__options')
                .filter(function () {
                    return $(this).data('type') == 'by-length';
                })
                .find('.our-craft-filter-modal__content__dropdowns__dropdown__options__option')
                .not(`.${activeClassName}`)
                .each(function () {
                    var lengthMin = $(this).data('min');
                    var lengthMax = $(this).data('max');
                    var counter = 0;
                    $('.boat-data').each(function (index) {
                        var length = $(this).data('boatlength');
                        if (length <= lengthMax && length >= lengthMin && $(this).parent().not('.would-be-hidden').length) counter++;
                    });
                    if (!counter) $(this).addClass('is-disabled');
                });
        }
    }
};

window.resetFilters = function() {
    $($('.js-data-filter-select-length')[1]).find('.custom-option-our-craft')[0].click();
    $($('.js-data-filter-select-berths')[1]).find('.custom-option-our-craft')[0].click();
    $($('.js-data-filter-select-crew')[1]).find('.custom-option-our-craft')[0].click();

    $($('.js-data-filter-select-length')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
    $($('.js-data-filter-select-berths')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
    $($('.js-data-filter-select-crew')[1]).find('.custom-option-our-craft').removeClass('is-disabled');
};

window.filterSubmitMobileAndTablet = function () {
    let filtersValues = getFiltersValues();
    filterMethod(filtersValues[0], filtersValues[1], filtersValues[2], true);
    openFilterModal();
};

const getFiltersValues = function() {
    const activeClassName =
        'our-craft-filter-modal__content__dropdowns__dropdown__options__option__active';
    const berthsFilterValue = $(
        '.our-craft-filter-modal__content__dropdowns__dropdown__options'
    )
        .filter(function () {
            return $(this).data('type') == 'by-berths';
        })
        .find(`.${activeClassName}`)
        .text()
        .trim();
    const lengthFilterValue = $(
        '.our-craft-filter-modal__content__dropdowns__dropdown__options'
    )
        .filter(function () {
            return $(this).data('type') == 'by-length';
        })
        .find(`.${activeClassName}`)
        .map( function() {
            return $(this).data('min') + '-' + $(this).data('max');
        })[0];
    const crewFilterValue = $(
        '.our-craft-filter-modal__content__dropdowns__dropdown__options'
    )
        .filter(function () {
            return $(this).data('type') == 'by-crew';
        })
        .find(`.${activeClassName}`)
        .text()
        .trim();

    return [berthsFilterValue, lengthFilterValue, crewFilterValue];
};

const filterMethod = function (
    berthsFilterValue,
    lengthFilterValue,
    crewFilterValue,
    isReal
) {
    let newElements;

    $('.boat-data').map(function () {
        $(this).parent().removeClass('would-be-hidden');
        $(this).parent().css('display', 'block');
    });

    $('.our-craft__category-section__list-section').removeClass('two-items');
    $('.our-craft__category-section__list-section').children().removeClass('two-elements-first');
    $('.our-craft__category-section__list-section').children().removeClass('two-elements-second');
    let innerCounter = 1;
    let validElementsCounter = 0;
    newElements = $('.boat-data')
        .filter(function (el, i) {
            $(this).parent().removeClass('tablet-filter-margin');
            $(this).parent().removeClass('tablet-filter-margin-second');
            let filter = true;

            if (berthsFilterValue) {
                filter = berthsFilterValue <= $(this).data('maxberths') && berthsFilterValue >= $(this).data('minberths');
            }

            if (lengthFilterValue) {
                var lengthMin = lengthFilterValue.toString().split('-')[0];
                var lengthMax = lengthFilterValue.toString().split('-')[1];
                filter = $(this).data('boatlength') >= lengthMin && $(this).data('boatlength') <= lengthMax && filter;
            }

            if (crewFilterValue) {
                filter = crewFilterValue == $(this).data('numberofcrew') && filter;
            }

            if (filter == true) {
                if (
                    ($(window).width() > 960 &&
                        (window.location.href.endsWith('our-craft') ||
                            window.location.href.endsWith('our-craft/')
                        )
                    ) ||
                    $(window).width() > 1200
                ) {
                    if (validElementsCounter == 1 || innerCounter == 3) {
                        innerCounter = 0;
                        $(this)
                            .parent()
                            .addClass(
                                'our-craft__category-section__list-section__yacht-card__bottom'
                            );
                    } else {
                        $(this)
                            .parent()
                            .removeClass(
                                'our-craft__category-section__list-section__yacht-card__bottom'
                            );
                    }
                    validElementsCounter++;
                    innerCounter++;
                } else {
                    if (innerCounter % 2 == 0) {
                        $(this).parent().addClass('tablet-filter-margin-second');
                    } else {
                        $(this).parent().addClass('tablet-filter-margin');
                    }

                    innerCounter++;
                }
            }

            return !filter;
        })
        .get();

    for (var i = 0; i < newElements.length; i++) {
        if(isReal) {
            $(newElements[i]).parent().css('display', 'none');
        } else {
            $(newElements[i]).parent().addClass('would-be-hidden');
        }
    }

    if(!isReal) return;

    const shownElementsLength = $(
        '.our-craft__category-section__list-section__yacht-card:visible'
    ).length;

    if (shownElementsLength % 3 == 2 || shownElementsLength == 2) {
        $('.our-craft__category-section__list-section').addClass('two-items');

        $('.our-craft__category-section__list-section')
            .children()
            .removeClass('last');
        $('.our-craft__category-section__list-section')
            .children()
            .removeClass('last-but-one');

        $('.our-craft__category-section__list-section__yacht-card:visible')
            .last()
            .addClass('last');
        $(
            $('.our-craft__category-section__list-section__yacht-card:visible')[
            shownElementsLength - 2
                ]
        ).addClass('last-but-one');

        if (shownElementsLength <= 2) {
            $(
                $('.our-craft__category-section__list-section__yacht-card:visible')[
                shownElementsLength - 2
                    ]
            ).addClass('two-elements-first');
            $('.our-craft__category-section__list-section__yacht-card:visible')
                .last()
                .addClass('two-elements-second');
            $('.our-craft__category-section__list-section__yacht-card:visible')
                .last()
                .addClass('two-elements-second');
        }
    }
};

const handlerYachtCraftClick = function (event) {
    let div = $(event.target).parents(
        '.our-craft__category-section__list-section__yacht-card'
    );
    if (div.length == 0) {
        div = event.target;
    }

    const url = $(div).children('.boat-data').data('craft-details-url');
    window.location.href = url;
};

$('.our-craft__category-section__list-section__yacht-card').on(
    'click',
    handlerYachtCraftClick
);

if (window.screen.width <= 1200 && window.screen.width >= 721) {
    let newText = $('.smell-the-sea .text-block__heading')
        .text()
        .replace(/&lt;br&gt;/g, '')
        .trim();

    $('.smell-the-sea .text-block__heading').text(newText);
}

//done to adress changes on s3 and s4 pages
const waveSvgPath = $('.our-craft-banner>.wave>path');
const ourCraftSection = $('.our-craft-section');
const url = window.location.href.split('/');
if (url.includes('range')) {
    waveSvgPath.attr('fill', '#F9F8F6');
    ourCraftSection.css({
        'padding-bottom': 124,
        'background-color': '#F9F8F6'
    });
} else {
    ourCraftSection.css({
        position: 'relative'
    });
    waveSvgPath.attr('fill', '#F9F8F6');
}

